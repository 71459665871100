import React from "react";
import Header from "../components/header";

const Layout = ({ children }) => (
  <div id="container">
    <Header />
    <main>
      <pre>Using standard layout</pre>
      {children}
    </main>
  </div>
);

export default Layout
